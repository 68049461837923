@import "colors";

button, .btn {
  border: none;
  background-color: $accent-color;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  font-family: inherit;
  line-height: 1.2em;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  &.full {
    width: 100%;
  }

  &.small {
    padding: 0.5rem;
  }

  &.secondary {
    background-color: $note-color;
  }

  &.danger {
    background-color: $error-color;
  }

  &:hover {
    background-color: $accent-color-hover;
    color: white;
    text-decoration: none;
  }

  &:active,&.active {
    background-color: $accent-color-active;
  }
  &:disabled {
    background-color: $border-color;
  }



}

.buttons {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
}
