
$accent-color: #00689f;
$accent-color-hover: lighten($accent-color, 20%);
$accent-color-active: lighten($accent-color, 10%);
$border-color: #ccc;
$note-bg-color: #eee;
$note-color: #777777;
$error-color: #e00009;
$error-bg-color: #f9e1df;
$online-color: #1db600;
$pending-color: #d6b500
