@import "colors";

label {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}


input[type='text'], input[type='number'], input[type='password'], textarea {
  display: block;
  width: 100%;
  font-size: 1rem;
  border: 1px solid $border-color;
  padding: 0.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box;

  &.ng-touched.ng-invalid {
    border-color: $error-color;
  }
}

