@import "styles/colors";
@import "styles/inputs";
@import "styles/buttons";
@import '~@angular/cdk/overlay-prebuilt.css';

html {
  font-family: Verdana, sans-serif;
  font-size: 12pt;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  line-height: 1.2em;
}

a {
  color: $accent-color;
  text-decoration: none;
  &:hover {
    color: $accent-color-hover;
    text-decoration: underline;
  }
}

.small {
  font-size: 0.8rem;
}


app-root {
  display: grid;
  min-height: 100vh;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;

  > header {
    border-bottom: 1px solid #ccc;

  }
  > main {
    padding: 1rem;
  }
  > footer {
    border-top: 1px solid #ccc;
    .footer {
      padding: 1rem;
    }
  }
}


.primary-note {
  text-align: center;
  font-size: 1.5em;
  color: $note-color;
  background-color: $note-bg-color;
  padding: 2rem 0;
  margin: 1rem 0;
}

@media only screen and (min-width: 800px) {
  .main-nav {
    width: 800px;
    margin: 0 auto;
  }
  main {
    width: 800px;
    margin: 0 auto;
  }
  .footer {
    width: 800px;
    margin: 0 auto;
  }

}
